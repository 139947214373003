<template>
    <div>
        <div class="card bs-4">
            <div class="fl-te-c">
                <h4 class="mb-3">TEAM SOCIAL MEDIA DETAILS</h4>
                <btn text="Back" @click="$router.go(-1)" size="sm"/>
            </div>
            <edit-team-social-media v-if="details" :initial-data="details" @success="loadDetails"/>
            <p v-else class="text-center p-3">No data available.</p>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/cms-urls';
import EditTeamSocialMedia from './EditTeamSocialMedia';

export default {
    name       : 'DetailsPage',
    components : { EditTeamSocialMedia },
    data () {
        return {
            loading : false,
            details : '',
            id      : this.$route.params.id
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.cms.teamSocialMedia.details, { id : this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                this.details.team = this.details.team.id;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type : 'danger'
                });
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>

</style>
